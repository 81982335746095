.chat-box .chat-message { 
  color: var(--ion-color-step-750) !important;
  background: var(--ion-color-step-50) !important;
  margin-bottom: 10px;
  padding: 10px 15px;
  border-radius: 20px; 
  /* letter-spacing: .5px; */
}
/* .chat-box .chat-user { */
.chat-box .chat-customer {
  background: #f0f0f0;
  border-bottom-right-radius: 0px;
}
.chat-box .chat-provider {
  background: #d0d0d0;
  border-top-left-radius: 0px;
}

/* ion-content {
  --offset-bottom: 150px !important;
} */